<template>
  <div>
    <Menu />
    
    <section>
      <div class="container-fluid pt-3 g-0">
        <div class="row g-0">
          <div class="col text-center">
            <div class="global-banner">
              <div class="row">
                <div class="col-lg-6 mx-auto align-self-center switch-white pb-5">
                  <h1 class="display-2 pb-5"><strong>Global Connect</strong></h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="sectionTwo pt-5 pb-5 mb-5">
      <div class="container g-0 pt-5">
        <div class="row g-0 ">
          <div class="col-lg-12">
            <div class="h-100 p-5 text-center">
              <h2 class="switch-blue mb-4">Residency by Investment</h2>
              <p>In an era marked by globalization, individuals seek opportunities beyond their borders for personal and professional growth. South Africans, in particular, are increasingly exploring avenues to diversify their investments, enhance their quality of life, and secure international opportunities.</p>
              <p>One avenue gaining prominence is the concept of the Golden Visa – a residency-by-investment program that offers foreign nationals the chance to obtain residency or citizenship in exchange for making a significant investment in the host country.</p>
              <center><a href="/contact" target="_blank" class="btn cta-btn mt-5"><strong>Contact us for more information</strong></a></center>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- <section class="sectionTwo pb-5">
      <div class="container g-0">
        <div class="row g-0 ">
          <div class="col-md-6 p-5 align-self-center">
            <div class="h-100 p-5">
              <h2 class="switch-blue mb-4"><strong>Our awards and accolades</strong></h2>
              <p>While our first commitment is always to our customers and delivering the highest-quality service, it’s nice to receive industry recognition. Some of our recent awards include: </p>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="h-100"><img src="images/about-awards.jpg" class="mw-100"/></div>
          </div>
        </div>
      </div>
    </section>
    <section class="sectionTwo switch-bg-lgrey">
      <div class="container g-0">
        <div class="row g-0">
          <div class="col-md-12  p-5 ps-0 align-self-center">
            <div class="h-100 p-5 ps-0">
              <h2 class="switch-blue mb-4"><strong>Our team</strong></h2>
              <p>Our focus on delivering a highly personalised and professional service is what we believe sets us apart from our competition. Backed by the innovative Chas Everitt International brand, we ensure that we make a positive, lasting impact on the lives of every customer, consistently going above and beyond their expectations.</p>
              <a href="/team" class="btn cta-btn">Read more</a>
            </div>
          </div>
        </div>
      </div>
    </section> 
    <section class="sectionTwo switch-bg-lgrey">
      <div class="container g-0">
        <div class="row g-0 ">
          <div class="col-md-12 p-5 ps-0 pt-0 align-self-center">
            <div class="h-100 p-5 ps-0">
              <h2 class="switch-blue mb-4"><strong>Join us</strong></h2>
              <p>If you’re interested in joining our dynamic, award-winning team and building your career, get in touch.</p>
              <a href="/contact" class="btn cta-btn">Get in touch</a>
            </div>
          </div>
        </div>
      </div>
    </section> -->

    <Footer />
  
  </div>
</template>
<script>

import Menu from '@/components/Menu.vue'
import Footer from '@/components/Footer.vue'

export default {
  components: {
    Menu, Footer 
  },
  mounted() {
  },
  data() {
    return {
      data: {
        listings: []
      }
    }
  },
  methods: {

  }
}
</script>
<style scoped>
  
</style>